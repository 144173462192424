import React from 'react'
import PropTypes from 'prop-types'
import {withAuth} from "../auth";
import {Button} from "@mui/material";

const SignOutButton = ({ children, className, auth: { logout, signedIn } }) => {

  const handleSignOut = () => {
    logout()
  }

  return (
    <div style={{ width: '100%'}}>
      {signedIn && (
      <div>
        <Button
          className={className}
          onClick={handleSignOut}>
          {children}
        </Button>
      </div>
      )}
    </div>
  )
}

SignOutButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  auth: PropTypes.any
}

export default withAuth(SignOutButton)
