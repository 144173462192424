const globalStyles = {
  '@global': {
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
      background: 'linear-gradient(180deg, #3eb660 10%, #FFFFFF 100%) !important',
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    ".App": {
      height: '100%',
      width: '100%',
    }
  },
}

export default globalStyles
