import React, {useEffect, useState} from 'react'
import validate from 'validate.js'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import Page from "../../components/Page";
import {withAuth} from "../../auth";
import {Navigate} from 'react-router'
import CustomTextField from "../../components/CustomTextField";
import {makeStyles} from "@mui/styles";
import {Button, Grid, Link, Typography} from "@mui/material";

const schema = {
  username: {
    presence: {allowEmpty: false, message: '^Nutzername wird benötigt'},
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: {allowEmpty: false, message: '^Passwort wird benötigt'},
    length: {
      maximum: 128,
    },
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center"
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    height: '100%',
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: '40%',
  },
  typography: {
    color: theme.palette.white,
    paddingTop: theme.spacing(2),
  },
  buttonBox: {
    alignSelf: "flex-end",
    paddingBottom: theme.spacing(2)
  },
  form: {
    height: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  formGrid: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "flex-start",
    height: '100%',
  },
  suggestion: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  passwordField: {
    paddingTop: theme.spacing(1)
  }
}))

const SignIn = ({
                  auth:
                    {error: authError, login, signedIn, newPasswordRequired}
                }) => {

  const classes = useStyles()

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })
  const [autoTry, setAutoTry] = useState(false)

  const location = useLocation()
  const username = new URLSearchParams(location.search).get("user")
  const password = new URLSearchParams(location.search).get("password");

  useEffect(() => {
    if (username && password) {
      const email = Buffer.from(username, "base64").toString()
      const code = Buffer.from(password, "base64").toString()
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          username: email,
          password: code
        },
        touched: {
          ...formState.touched,
          username: true,
          password: true,
        },
      }))
    } else {
      if (username) {
        const email = Buffer.from(username, "base64").toString()
        setFormState({
          ...formState,
          values: {
            ...formState.values,
            username: email
          }
        })
      }
      if (password) {
        const code = Buffer.from(password, "base64").toString()
        setFormState({
          ...formState,
          values: {
            ...formState.values,
            password: code
          }
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: (authError && authError !== 'The user is not authenticated') ? {
        username: [getGermanError()],
        password: [getGermanError()]
      } : errors || {},
    }))
  }, [formState.values, authError])

  useEffect(() => {
    if (formState.values.username && formState.values.password && username && password && !autoTry) {
      setAutoTry(true)
      handleSignIn()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const getGermanError = () => {
    return "Falscher Nutzername oder Passwort.";
  }

  const handleSignIn = (event) => {
    if(event) {
      event.preventDefault()
    }
    const {values: {username, password}} = formState
    login(username.trim(), password)
  }

  const hasError = field => !!((formState.touched[field] && formState.errors[field]) || formState.values[field] === '')

  return (
    <Page title={'Flotteladen Fahrer App'} suppressAuthGuard>
      {signedIn && <Navigate to="/app"/>}
      {newPasswordRequired && <Navigate to="/new-password"/>}
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container>
          <Grid
            className={classes.content}
            item
            xs={12}>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <div className={classes.headerBox}>
                  <img
                    alt="greetings"
                    src="/assets/greetings.png"/>
                  <Typography align={"center"} className={classes.typography}>
                    Bitte melde dich an
                  </Typography>
                </div>
                <form
                  className={classes.form}
                  onSubmit={handleSignIn}>
                  <Grid container className={classes.formGrid}>
                    <Grid item xs={12}>
                      <CustomTextField
                        hasError={hasError}
                        idName='username'
                        type='email'
                        handleChange={handleChange}
                        formStateError={formState.errors.username?.[0]}
                        formStateValue={formState.values.username}
                        label={'E-Mail'}
                      />
                      <div className={classes.passwordField}>
                        <CustomTextField
                          hasError={hasError}
                          idName='password'
                          type='password'
                          handleChange={handleChange}
                          formStateError={formState.errors.password?.[0]}
                          formStateValue={formState.values.password}
                          label={'Passwort'}
                        />
                      </div>
                      <Typography
                        className={classes.suggestion}
                        color="white"
                        variant="body1"
                        align={"center"}
                      >
                        <Link component={RouterLink} to="/reset-password"
                              color={"white"}>
                          Passwort vergessen?
                        </Link>
                      </Typography>

                    </Grid>
                    <Grid item xs={12} className={classes.buttonBox}>
                      <Button
                        className={classes.signInButton}
                        id={"submit"}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained">
                        Anmelden
                      </Button>
                      <Typography variant="body1" color={'white'}>
                        &copy;{' '}
                        <Link
                          color={"white"}
                          component="a"
                          href="https://www.flotteladen.de/"
                          target="_blank">
                          Flotteladen
                        </Link>
                        . 2022
                        {' '}
                        <Link
                          color={"white"}
                          href="/imprintFlotteladen">
                          Impressum
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  )
}

export default withAuth(SignIn)
