const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S*$/

export const VALIDATION_SCHEMA_PASSWORD = {
  presence: { allowEmpty: false, message: '^Passwort erforderlich' },
  length: {
    maximum: 99,
    minimum: 8,
    message: '^Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen erforderlich',
  },
  format: {
    pattern: PASSWORD_PATTERN,
    message: '^Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen erforderlich',
  },
}

export const VALIDATION_SCHEMA_EMAIL = {
  presence: { allowEmpty: false, message: '^E-Mail erforderlich' },
  email: {
    message: '^Fehlerhafte E-Mail',
  },
}
