import typography from '../typography'

const muiTableCell = {
  styleOverrides: {
    root: {
      ...typography.body1,
      fontSize: '12px',
      borderBottom: "none",
      padding: '0px'
    }
  },
}

export default muiTableCell
