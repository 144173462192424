import {Card, Grid, Typography} from "@mui/material";
import ChargingPointSvg from "./ChargingPointSvg";
import React from "react";
import {makeStyles} from "@mui/styles";
import StatusTranslation from "./StatusTranslation";

const useStyles = makeStyles(theme => ({
  box: {
    paddingTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: '136px',
    height: theme.spacing(18)
  },
  customChargingPointIcon: {
    height: '80px'
  },
  gridContainer: {
    height: '100%'
  },
  nameTypography: {
    fontWeight: 700
  }
}))

const ChargingPointListCard = ({ chargingPoint, onClick }) => {

  const classes = useStyles()

  return (

    <Card className={classes.box} elevation={2} onClick={onClick}>
      <Grid className={classes.gridContainer} container justifyContent={"flex-start"} alignItems={"flex-start"}>
        <Grid item xs={12} alignContent={"center"}>
          <ChargingPointSvg
            chargingStatus={chargingPoint.currentStatus?.status}
            fontSize={'80px'}
            customIconClass={classes.customChargingPointIcon}
            lockMarginLeft={5}
            lockMarginBottom={'-27px'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"body2"} align={"center"} className={classes.nameTypography}>
            {chargingPoint.name}
          </Typography>
          <Typography variant={"body2"} align={"center"}>
            <StatusTranslation status={chargingPoint.currentStatus?.status}/>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ChargingPointListCard
