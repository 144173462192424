import {makeStyles} from "@mui/styles";
import {Outlet} from "react-router-dom";
import React from "react";
import Topbar from "./components/TopBar";


const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    paddingTop: 175,
  },
  content: {
    height: '100%',
  },
}))

const Amperfied = () => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Topbar/>
      <main className={classes.content}>
        <Outlet/>
      </main>
    </div>
  )
}

export default Amperfied
