import React from 'react'
import {makeStyles} from "@mui/styles";
import {Button, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center',
  },
  buttonBox: {
    width: '100%',
    display: "flex",
    justifyContent: "center"
  },
}))

const NotFound = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}>
        <Grid
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <div className={classes.content}>
            <Typography variant="h1" align={"center"} color={'white'}>
              404: Wir konnten die Seite leider nicht finden
            </Typography>
            <Typography variant="subtitle2" align={"center"} color={'white'}>
              Entweder Sie haben einen falschen Link benutzt oder sind durch einen Fehler hier gelandet.
              Bitte versuchen Sie es nocheinmal unter Nutzung der Navigation
            </Typography>
          </div>
          <div className={classes.buttonBox}>
            <Button onClick={() => navigate('/app')} variant={"contained"} style={{ marginTop: 20 }}>
              Zurück zur Startseite
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default NotFound
