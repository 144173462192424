import {Button, Grid} from "@mui/material";
import ChargingPointSvg from "./ChargingPointSvg";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import ChargingModeToggle from "./ChargingModeToggle";
import ChargingValueDisplay from "./ChargingValueDisplay";
import {DateTime} from 'luxon'

const useStyles = makeStyles(theme => ({
  stationIconBox: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  statusTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: '248px'
  },
  chargingModeSwitchBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2.5)
  },
  timeTypography: {
    fontSize: 18
  },
  customChargingPointIcon: {
    height: '150px'
  },
  timeIconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(3)
  },
  timeBox: {
    paddingLeft: '0px'
  },
  startStopGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: '24px'
  },
  startStopButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    alignSelf: "center",
    width: '236px',
    height: '60px',
  },
  disabledStartStopButton: {
    alignSelf: "center",
    width: '236px',
    height: '60px',
    backgroundColor: '#bdbdbd !important',
    color: '#FFFFFF !important',
  },
}))

const ChargingPointOverview = ({ chargingPoint, otherChargingOptions, chargingOption, setChargingOption, startCharging, canCharge }) => {

  const classes = useStyles()
  const [enableButton, setEnableButton] = useState(canCharge)

  useEffect(() => {
    setEnableButton(canCharge)
  }, [canCharge])

  const getButtonLabel = () => {
    /*if (chargingPoint.status?.status === 'CHARGING') {
        return 'Ladevorgang Läuft...'
    } else {
    }

     */
    return 'Ladevorgang starten'
  }

  const calculateDuration = (status) => {
    const lastSessionStarted = status?.lastSessionStarted
    const lastSessionCompleted = status?.lastSessionCompleted

    const lastSessionStartedDate = DateTime.fromISO(status?.lastSessionStarted)
    const lastSessionCompletedDate = DateTime.fromISO(status?.lastSessionCompleted)

    if (lastSessionStarted && (!lastSessionCompleted || lastSessionStartedDate > lastSessionCompletedDate)) {
      return DateTime.now().diff(lastSessionStartedDate).toFormat('hh:mm')
    } else if (lastSessionStarted && lastSessionCompleted && status?.status === 'CHARGING_COMPLETED' && lastSessionStartedDate < lastSessionCompletedDate) {
      return lastSessionCompletedDate.diff(lastSessionStartedDate).toFormat('hh:mm')
    }
    return '00:00'
  }

  const getPowerString = (status) => {
    const powerString = status?.power?.toFixed(1).replace('.', ',')
    return (status?.status === 'CHARGING' || status?.status === 'CHARGING_STARTED_EV') && powerString.length > 0 ? powerString : '0'
  }

  const getEnergyString = (status) => {
    const energyString = status?.energy?.toFixed(1).replace('.', ',')
    return status && energyString
  }

  return (

      <Grid container spacing={2} justifyContent={"center"}>
        { otherChargingOptions &&
          <Grid item container xs={12} alignItems={"center"} className={classes.chargingModeSwitchBox}>
            <ChargingModeToggle
              chargingOption={chargingOption}
              setChargingOption={setChargingOption}
            />
          </Grid>
        }
        <Grid item xs={12} className={classes.stationIconBox}>
          <ChargingPointSvg
            chargingStatus={chargingPoint.status ? chargingPoint.status.status : ''}
            fontSize={'150px'}
            customIconClass={classes.customChargingPointIcon}
            lockMarginLeft={14}
            lockMarginBottom={'-29px'}
          />
        </Grid>
        <Grid item container xs={12} className={classes.statusTextBox}>
          <Grid item container xs={4} alignItems={"center"} justifyContent={"center"} className={classes.timeBox}>
            <ChargingValueDisplay
              value={getPowerString(chargingPoint.status)}
              name={'Leistung'}
              unit={chargingPoint?.status?.powerUom}
            />
          </Grid>
          <Grid item container xs={4} alignItems={"center"} justifyContent={"center"} className={classes.timeBox}>
            <ChargingValueDisplay
              value={calculateDuration(chargingPoint.status)}
              name={'Zeit'}
              unit={'h'}
            />
          </Grid>
          <Grid item container xs={4} alignItems={"center"} justifyContent={"center"} className={classes.timeBox}>
            <ChargingValueDisplay
              value={getEnergyString(chargingPoint.status)}
              name={'Lademenge'}
              unit={chargingPoint?.status?.energyUom}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.startStopGrid}>
          {
            enableButton ?
              <Button variant={"contained"}
                      className={classes.startStopButton}
                      onClick={() => {
                        startCharging()
                      }}
              >
                {getButtonLabel()}
              </Button>
              :
              <Button variant={"contained"}
                      className={classes.disabledStartStopButton}
                      disabled
              >
                {getButtonLabel()}
              </Button>

          }
        </Grid>
      </Grid>
  )
}

export default ChargingPointOverview
