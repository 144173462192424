import React, {useEffect, useState} from 'react'
import {useParams} from "react-router";
import {Card, CircularProgress, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Page from "../../components/Page";
import StatusTranslation from "../../components/StatusTranslation";
import ChargingTimeCard from "../../components/ChargingTimeCard";
import {apiClient} from "../../apiClient";
import ChargingPointOverview from "../../components/ChargingPointOverview";
import {useSnackbar} from "notistack";
import Poller from "../../components/Poller";
import PullToRefresh from "react-simple-pull-to-refresh";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  box: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: '600px'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center"
  },
  contentBody: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '600px'
  },
  cpNameTypography: {
    color: theme.palette.white,
    fontSize: 25,
    paddingTop: '10vh'
  },
  cpStatusTypography: {
    color: theme.palette.white,
    paddingTop: theme.spacing(2)
  },
  pullDown: {
    ".ptr__pull-down": {
      ".lds-ellipsis div ": {
        background: theme.palette.white
      },
    }
  }
}))

const pollerIntervalInMs = 5000

const ChargingPoint = () => {
  const classes = useStyles()
  const {clientId, pointId} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const CHARGEMODE_URL = '/api/app/client/{clientId}/chargingpoints/{chargingPointId}/selected-charge-mode'
  const POINT_URL = '/api/app/client/{clientId}/chargingpoints/{chargingPointId}'
  const SESSIONS_URL = '/api/app/client/{clientId}/chargingpoints/{chargingPointId}/sessions'
  const STARTSTOP_URL = '/api/app/client/{clientId}/chargingpoints/{chargingPointId}/lock'
  const CHARGE_ADVICE_URL = '/api/app/client/{clientId}/ems/configuration/charge-advice'
  const [chargingPoint, setChargingPoint] = useState()
  const [chargingSessions, setChargingSessions] = useState([])
  const [chargingSessionsLast, setChargingSessionsLast] = useState(false)
  const [chargingSessionsPageable, setChargingSessionsPageable] = useState()
  const [chargingOption, setChargingOption] = useState()
  // determines if other options are available
  const [otherChargingOptions, setOtherChargingOptions] = useState(false)

  const startStopChargingPoint = () => {
    let payload
    if (chargingPoint.status.status === 'CHARGING') {
      payload = 'STOP'
    } else {
      payload = 'START'
    }
    let url = STARTSTOP_URL.replace(/{clientId}/g, clientId)
    url = url.replace(/{chargingPointId}/g, pointId)

    apiClient.put(url, payload)
      .then(() => {
        enqueueSnackbar('Ladevorgang wird gestartet', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Der Ladevorgang konnte nicht gestartet werden!', {
          variant: 'error',
        })
      })
  }

  useEffect(() => {
    fetchData()
    fetchChargingPointData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReload = (resolve, reject) => {
    let pointUrl = POINT_URL.replace(/{clientId}/g, clientId)
    pointUrl = pointUrl.replace(/{chargingPointId}/g, pointId)
    apiClient.get(pointUrl)
      .then((r) => {
        setChargingPoint(r.data)
        handleChargingOptionReload(r.data)
      })
      .catch((e) => {
        console.log('error ' + e)
      })
    let sessionsUrl = SESSIONS_URL.replace(/{clientId}/g, clientId)
    sessionsUrl = sessionsUrl.replace(/{chargingPointId}/g, pointId)
    apiClient.get(sessionsUrl)
      .then((r) => {
        setChargingSessions(r.data.content)
        setChargingSessionsLast(r.data.last)
        setChargingSessionsPageable(r.data.pageable)
        resolve()
      })
      .catch((e) => {
        console.log('error ' + e)
        reject()
      })
  }

  const fetchChargingPointData = (polledLoad) => {
    let adviceURL = CHARGE_ADVICE_URL.replace(/{clientId}/g, clientId)
    let pointUrl = POINT_URL.replace(/{clientId}/g, clientId)
    pointUrl = pointUrl.replace(/{chargingPointId}/g, pointId)
    apiClient.get(pointUrl)
      .then((r) => {
        setChargingPoint(r.data)
        if (otherChargingOptions || !polledLoad) {
          apiClient.get(adviceURL)
            .then((response) => {
              setOtherChargingOptions(response.data.enabled)
              if (!polledLoad) {
                handleChargingOptionReload(r.data, response.data.enabled)
              }
            })
            .catch((e) => {
              console.log('error ' + e)
            })
        }
      })
      .catch((e) => {
        console.log('error ' + e)
      })
  }

  const fetchData = () => {
    let sessionsUrl = SESSIONS_URL.replace(/{clientId}/g, clientId)
    sessionsUrl = sessionsUrl.replace(/{chargingPointId}/g, pointId)
    apiClient.get(sessionsUrl)
      .then((r) => {
        setChargingSessions(r.data.content)
        setChargingSessionsLast(r.data.last)
        setChargingSessionsPageable(r.data.pageable)
      })
      .catch((e) => {
        console.log('error ' + e)
      })
  }

  const fetchMoreSessionsData = () => {
    if (!chargingSessionsLast && chargingSessionsPageable) {
      let url = SESSIONS_URL.replace(/{clientId}/g, clientId)
      url = url.replace(/{chargingPointId}/g, pointId)
      apiClient.get(url, {cursor: chargingSessionsPageable.cursor})
        .then((r) => {
          let tempSessions = chargingSessions
          r.data.content.forEach((session) => {
            tempSessions.push(session)
          })
          setChargingSessions(tempSessions)
          setChargingSessionsLast(r.data.last)
          setChargingSessionsPageable(r.data.pageable)
        })
        .catch((e) => {
          console.log('error ' + e)
        })
    }
  }

  const switchChargingMode = (event, quickOtherChargingOptions) => {
    setChargingOption(event.target.value)
    if (otherChargingOptions || quickOtherChargingOptions) {
      let url = CHARGEMODE_URL.replace(/{clientId}/g, clientId)
      url = url.replace(/{chargingPointId}/g, pointId)
      apiClient.put(url, event.target.value)
        .then(() => {
          enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen.', {
            variant: 'success',
          })
        })
        .catch(() => enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe.', {
          variant: 'error',
        }))
    }
  }

  const handleChargingOptionReload = (data, quickOtherChargingOptions) => {
    if ((data.status.status === 'CHARGING_COMPLETED' || data.status.status === 'AVAILABLE') && data.defaultChargeMode !== 'LAST_USED') {
      switchChargingMode({ target: {value: data.defaultChargeMode}}, quickOtherChargingOptions)
    } else if (!chargingOption) {
      switchChargingMode({ target: {value: data.selectedChargeMode}}, quickOtherChargingOptions)
    }
  }

  return (
    <Page title={'Flotteladen Fahrer App'}
          requiredRole={['FL_support', 'FL_cp_user']}>
      <PullToRefresh onRefresh={() => new Promise((resolve, reject) => {handleReload(resolve, reject)})} pullingContent={<div/>} refreshingContent={<CircularProgress style={{'color' : 'white'}}/>}>
      {chargingPoint &&
        <div className={classes.root}>
          <Poller action={() => fetchChargingPointData(true)} interval={pollerIntervalInMs}/>
          <Grid
            className={classes.gridContainer}
            container>
            <Grid
              className={classes.content}
              item
              xs={12}>
              <div className={classes.contentBody}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align={"center"}
                                className={classes.cpNameTypography}>
                      {chargingPoint.name.toUpperCase()}
                    </Typography>
                    <Typography align={"center"}
                                className={classes.cpStatusTypography}>
                      {<StatusTranslation
                        status={chargingPoint.status ? chargingPoint.status.status : ''}/>}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.grid}>
                    <Card className={classes.box} elevation={2}>
                      <ChargingPointOverview
                        chargingPoint={chargingPoint}
                        otherChargingOptions={otherChargingOptions}
                        chargingOption={chargingOption}
                        setChargingOption={switchChargingMode}
                        startCharging={startStopChargingPoint}
                        canCharge={['UNAUTHORIZED'].includes(chargingPoint.status?.status)}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} className={classes.grid}>
                    <ChargingTimeCard chargingSessions={chargingSessions}
                                      fetchMoreData={fetchMoreSessionsData}
                                      last={chargingSessionsLast}/>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      }
      </PullToRefresh>
    </Page>
  )
}

export default ChargingPoint
