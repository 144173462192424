import PropTypes from "prop-types";
import {withStyles} from "@mui/styles";

const styles = () => ({
  '@global': {
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
      margin: 0,
      backgroundColor: '#007B43',
    },
    body: {
      height: '100%',
      width: '100%',
      margin: 0,
      background: 'linear-gradient(180deg, #3FAD49 50%, #007B43 100%) !important',
      backgroundRepeat: "no-repeat !important",
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    ".App": {
      height: '100%',
      width: '100%',
    }
  },
});

function MyCssBaseline() {
  return null;
}

MyCssBaseline.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyCssBaseline);
