import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {makeStyles} from "@mui/styles";
import {alpha, Button, ListItem} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: alpha(theme.palette.primary.contrastText, 0.5),
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.4),
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.contrastText,
      },
    }
  },
  icon: {
    color: alpha(theme.palette.primary.contrastText, 0.5),
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.contrastText, 0.4),
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.contrastText,
    },
  },
}))

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <ListItem
      className={clsx(classes.item, className)}
      {...rest}
    >
      <Button
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </Button>
    </ListItem>
  )
}

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
}

export default NavItem
