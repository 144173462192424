import React from 'react'
import EmailForm from '../../components/EmailForm'
import {withAuth} from "../../auth";
import Page from "../../components/Page";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  grid: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  backBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
  backButton: {
    color: theme.palette.white,
  }
}))

const ResetPassword = ({ auth: { error, initPasswordReset } }) => {

  const classes = useStyles()
  const navigate = useNavigate()

  const handleSubmit = (values) => initPasswordReset(values.email)

  return (
    <Page title={'Flotteladen Fahrer App'} suppressAuthGuard>
      <div className={classes.grid}>
        <div className={classes.backBox}>
          <Button className={classes.backButton} onClick={() => navigate(-1)}>
            <ArrowBackIosIcon/>
            Zurück
          </Button>
        </div>
        <div className={classes.contentBody}>
          <EmailForm onSubmit={handleSubmit} error={error} className={classes.form}/>
        </div>
      </div>
    </Page>
  )
}

export default withAuth(ResetPassword)
