import {Card} from "@mui/material";
import AmperfiedPage from "../../components/AmperfiedPage";
import {makeStyles} from "@mui/styles";
import raw from './privacy.txt';
import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";


const useStyles = makeStyles(theme => ({
  containerRoot: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  rowSpacing: {
    marginBottom: theme.spacing(3),
  },
}))


const Privacy = () => {
  const classes = useStyles()
  const [text, setText] = useState()

  useEffect(() => {
    fetch(raw)
      .then(r => r.text())
      .then(textFromFile => {
        setText(textFromFile)
      });
  },[])

  return (
    <AmperfiedPage>
      <Card
        className={classes.containerRoot}>
        <ReactMarkdown>
          {text}
        </ReactMarkdown>
      </Card>
    </AmperfiedPage>
  )
}

export default Privacy
