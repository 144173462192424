import SettingsPage from "../../components/SettingsPage";
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
  Button,
  FormControl, FormHelperText, Grid,
  MenuItem,
  Select,
  TextField, Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {apiClient} from "../../apiClient";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router";

const useStyles = makeStyles(theme => ({
  rootDiv: {
    width: '100%',
  },
  form: {
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  labelTypography: {
    marginBottom: '3px'
  }
}))

const validationSchema = yup.object({
  gender: yup
    .string('Bitte gib dein Geschlecht an')
    .min(1, 'Bitte gib dein Geschlecht an')
    .required('Bitte gib dein Geschlecht an'),
  age: yup
    .string('Bitte trag dein Alter ein')
    .min(1, 'Bitte trag dein Alter ein')
    .required('Bitte trag dein Alter ein'),
});

const Feedback = () => {

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const TITLE = 'Feedback'
  const FEEDBACK_URL = '/api/app/feedback'

  const formik = useFormik({
    initialValues: {
      happiness: '',
      positiveText: '',
      negativeText: '',
      futureFeatures: '',
      general: '',
      gender: '',
      age: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      apiClient.post(FEEDBACK_URL, values)
        .then(() => {
          enqueueSnackbar('Feedback wurde gespeichert, danke!', {
            variant: 'success',
          })
          navigate(-1)
        })
        .catch(() => {
          enqueueSnackbar('Etwas ist schiefgelaufen.', {
            variant: 'error',
          })
        })
    },
  });


  return (

    <SettingsPage
      requiredRole={['FL_cp_user']}
      title={TITLE}
      navigatePath={-1}
    >
      <div className={classes.rootDiv}>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Schön, dass du Feedback geben möchtest. Als junges Unternehmen
                arbeiten wir stets an der Funktionalität unserer Produkte und
                freuen uns über deine Erfahrungen mit der App.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Deine Antworten werden anonym erfasst und werden nur für
                Auswertungen innerhalb der Meser & Hirt UG (haftungsbeschränkt) verwendet und nicht
                an Dritte weitergegeben.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTypography}>
                Wie zufrieden bist du mit der App?
              </Typography>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  id={"happiness"}
                  labelId={"happiness"}
                  name={"happiness"}
                  value={formik.values.happiness}
                  onChange={formik.handleChange}
                  error={formik.touched.happiness && Boolean(formik.errors.happiness)}
                >
                  <MenuItem value={'Sehr zufrieden'}>Sehr zufrieden</MenuItem>
                  <MenuItem value={'zufrieden'}>zufrieden</MenuItem>
                  <MenuItem value={'weniger zufrieden'}>weniger
                    zufrieden</MenuItem>
                  <MenuItem value={'gar nicht zufrieden'}>gar nicht
                    zufrieden</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTypography}>
                Was gefällt dir an der Flotteladen App?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                id={"positiveText"}
                name={"positiveText"}
                value={formik.values.positiveText}
                onChange={formik.handleChange}
                error={formik.touched.positiveText && Boolean(formik.errors.positiveText)}
                helperText={formik.touched.positiveText && formik.errors.positiveText}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTypography}>
                Was gefällt dir am wenigsten an der Flotteladen App?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                id={"negativeText"}
                name={"negativeText"}
                value={formik.values.negativeText}
                onChange={formik.handleChange}
                error={formik.touched.negativeText && Boolean(formik.errors.negativeText)}
                helperText={formik.touched.negativeText && formik.errors.negativeText}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTypography}>
                Welche Funktionen wünschst du dir zukünftig von der Flotteladen App?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                id={"futureFeatures"}
                name={"futureFeatures"}
                value={formik.values.futureFeatures}
                onChange={formik.handleChange}
                error={formik.touched.futureFeatures && Boolean(formik.errors.futureFeatures)}
                helperText={formik.touched.futureFeatures && formik.errors.futureFeatures}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTypography}>
                Möchtest du uns noch etwas mitteilen?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                id={"general"}
                name={"general"}
                value={formik.values.general}
                onChange={formik.handleChange}
                error={formik.touched.general && Boolean(formik.errors.general)}
                helperText={formik.touched.general && formik.errors.general}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.labelTypography}>
                Geschlecht (Pflicht)
              </Typography>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  id={"gender"}
                  labelId={"gender"}
                  name={"gender"}
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  <MenuItem value={'männlich'}>männlich</MenuItem>
                  <MenuItem value={'weiblich'}>weiblich</MenuItem>
                  <MenuItem value={'divers'}>divers</MenuItem>

                </Select>
                {formik.touched.gender && Boolean(formik.errors.gender) &&
                  <FormHelperText
                    error
                  >
                    {formik.touched.gender && formik.errors.gender}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelTypography}>
                Alter (Pflicht)
              </Typography>
              <TextField
                fullWidth
                type={"number"}
                id={"age"}
                name={"age"}
                value={formik.values.age}
                onChange={formik.handleChange}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Vielen Dank, dass du dir die Zeit genommen hast, deine
                Erfahrungen mit der Flotteladen App mit uns zu teilen.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: '16px'}}>
              <Typography>
                Beste Grüße und eine sichere Fahrt
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Das Flotteladen-Team
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" fullWidth
                      type="submit">
                Speichern
              </Button>
            </Grid>
          </Grid>
        </form>

      </div>
    </SettingsPage>
  )
}

export default Feedback
