import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {withAuth} from "../../../../auth";
import {AppBar, Box, Button, IconButton, Toolbar} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topBar: {
    zIndex: theme.zIndex.drawer - 1
  },
  logo: {
    color: theme.palette.primary.contrastText
  },
}))

const Topbar = props => {
  const {styleClass, onSidebarOpen, ...rest} = props

  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <AppBar {...rest}
            elevation={0}
            position="fixed"
            className={classes.topBar}
            color={'transparent'}>
      <Toolbar className={classes.root}>
        <Box display="block" displayPrint="none">
          <Button
            style={{justifyContent: "flex-start"}}
            color="inherit"
            onClick={() => navigate('/')}
          >
            <img alt="edit" src="/assets/FL_Logo.svg"
                 style={{height: '25px'}}/>
          </Button>
        </Box>
        <Box display="block" displayPrint="none">
          {rest.auth.signedIn && (
            <IconButton onClick={onSidebarOpen}>
              <MenuIcon className={classes.logo}/>
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  styleClass: PropTypes.string,
}

export default withAuth(Topbar)
