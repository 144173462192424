import React, {useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useSnackbar} from 'notistack'
import {
  TextField,
  Button,
  Grid,
  Typography,
  InputAdornment, IconButton
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Passwort erforderlich')
    .min(8, 'Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen erforderlich')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S*$/, 'Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen erforderlich'),
  confirm: Yup.string()
    .required(' ')
    .oneOf([Yup.ref('password'), null], 'Passwörter stimmen nicht überein'),
})

const useStyles = makeStyles(theme => ({
  passwordRoot: {
    height: '100%',
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  formRoot: {
    height: '100%'
  },
  formBox: {
    alignSelf: "center",
    height: '80%'
  },
  headerBox: {
    minHeight: '20%'
  },
  buttonBox: {
    alignSelf: "flex-end",
  },
  title: {
    color: theme.palette.white,
  },
  labelTypography: {
    paddingTop: theme.spacing(1),
    fontSize: '12px',
    color: theme.palette.white,
  },
  formContainer: {
    height: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      boxShadow: `0 4px 4px 0 rgba(0, 0, 0, 0.25)`,
      background: "rgb(255, 255, 255)",
      borderRadius: `8px`,
    },
  },
}))

const NewPasswordForm = ({className, onSubmit, error, ...rest}) => {

  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [showPassword,setShowPassword] = useState(false);
  const [showConfirm,setShowConfirm] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const handleClickShowConfirm = () => setShowConfirm(!showConfirm)
  const handleMouseDownConfirm = () => setShowConfirm(!showConfirm)

  const formik = useFormik({
      initialValues: {
        password: '',
        confirm: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        onSubmit(values);
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen.', {
          variant: 'success',
        })
      }
    }
  )

  return (
    <Grid {...rest}
          className={clsx(classes.passwordRoot, className)} container
          spacing={2}
    >
      <Grid item className={classes.headerBox}>
        <Typography
          className={classes.title}
          variant="h4">
          PASSWORT VERGEBEN
        </Typography>
        <Typography
          className={classes.labelTypography}
          color="textSecondary"
          variant="body1">
          Sie müssen ein neues Passwort vergeben.
        </Typography>
      </Grid>
      <Grid item className={classes.formBox}>
        <form className={classes.formRoot} onSubmit={formik.handleSubmit}>
          <Grid container className={classes.formContainer}>
            <Grid item xs={12}>
              <Typography
                className={classes.labelTypography}>
                Passwort
              </Typography>
              <TextField
                className={classes.textField}
                InputLabelProps={{shrink: false}}
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                InputProps={{
                  endAdornment : (
                    <InputAdornment position={'end'}>
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography
                className={classes.labelTypography}>
                Passwort wiederholen
              </Typography>
              <TextField
                className={classes.textField}
                InputLabelProps={{shrink: false}}
                fullWidth
                name="confirm"
                type={showConfirm ? 'text' : 'password'}
                value={formik.values.confirm}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.confirm)}
                helperText={formik.errors.confirm}
                InputProps={{
                  endAdornment : (
                    <InputAdornment position={'end'}>
                      <IconButton
                        onClick={handleClickShowConfirm}
                        onMouseDown={handleMouseDownConfirm}
                      >
                        {showConfirm ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonBox}>
              <Button
                id={"submit"}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                variant="contained">
                Passwort speichern
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

NewPasswordForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default NewPasswordForm
