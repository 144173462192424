import React from 'react'
import {AmperfiedLayout, MainLayout, MinimalLayout} from "./layouts";
import { Navigate } from 'react-router-dom'
import ResetPasswordConfirm
  from "./pages/ResetPasswordConfirm/ResetPasswordConfirm";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NotFound from "./pages/NotFound/NotFound";
import NewPassword from "./pages/NewPassword/NewPassword";
import SignIn from "./pages/SignIn/SignIn";
import ChargingPoint from "./pages/ChargingPoint/ChargingPoint";
import ChargingPointList from "./pages/ChargingPointList/ChargingPointList";
import ResetPasswordCompleted
  from "./pages/ResetPasswordCompleted/ResetPasswordCompleted";
import Settings from "./pages/Settings/Settings";
import AppSettings from "./pages/AppSettings/AppSettings";
import Help from "./pages/Help/Help";
import InstallationHelp from "./pages/InstallationHelp/InstallationHelp";
import ImprintInfo from "./pages/ImprintInfo/ImprintInfo";
import PrivacyInfo from "./pages/PrivacyInfo/PrivacyInfo";
import Feedback from "./pages/Feedback/Feedback";
import Licenses from "./pages/Licenses/Licenses";
import SettingsInstallationHelp
  from "./pages/SettingsInstallationHelp/SettingsInstallationHelp";
import Imprint from "./pages/AmperfiedApp/Imprint";
import Privacy from "./pages/AmperfiedApp/Privacy";
import AGB from "./pages/AmperfiedApp/AGBs";

const routes = [
  {
    path: '/',
    element: <MinimalLayout/>,
    children: [
      { path: 'not-found', element: <NotFound/> },
      { path: '/sign-in', element: <SignIn/> },
      { path: '/new-password', element: <NewPassword/> },
      { path: '/reset-password', element: <ResetPassword/> },
      { path: '/confirm-password', element: <ResetPasswordConfirm/> },
      { path: '/reset-password-completed', element: <ResetPasswordCompleted/> },
      { path: '/help', element: <Help/>},
      { path: '/installation', element: <InstallationHelp/>},
      { path: '/imprintFlotteladen', element: <ImprintInfo/>},
      { path: '/privacyFlotteladen', element: <PrivacyInfo/>},
      { path: '/feedback', element: <Feedback/>},
      { path: '/licenses', element: <Licenses/>},
      { path: '/furtherInstallation', element: <SettingsInstallationHelp/>},
      { path: '*', element: <NotFound/> },
      { path: '', element: <Navigate to={'./app'}/> },
    ],
  },
  {
    path: '/',
    element: <AmperfiedLayout/>,
    children: [
      { path: '/imprint', element: <Imprint/>},
      { path: '/datenschutz', element: <Privacy/>},
      { path: '/agb', element: <AGB/>},
    ]
  },
  {
    path: 'app/:clientId/point/:pointId',
    element: <MainLayout/>,
    children: [
      { path: '', element: <ChargingPoint/>},
      { path: '*', element: <ChargingPoint/>}
    ],
  },
  {
    path: 'app',
    element: <MainLayout/>,
    children: [
      { path: '', element: <ChargingPointList/>},
      { path: '*', element: <ChargingPointList/>}
    ],
  },
  {
    path: 'app/:clientId/point/:pointId/settings',
    element: <MinimalLayout/>,
    children:  [
      { path: '', element: <Settings/>},
      { path: 'appSettings', element: <AppSettings/>},
      { path: 'installation', element: <SettingsInstallationHelp/>},
      { path: '*', element: <Settings/>}
    ]
  }
]

export default routes
