import React, {useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  labelTypography: {
    fontSize: '12px',
    color: theme.palette.white,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      boxShadow: `0 4px 4px 0 rgba(0, 0, 0, 0.25)`,
      background: "rgb(255, 255, 255)",
      borderRadius: `8px`,
    },
  },
}))

const CustomTextField = ({
                           hasError,
                           formStateError,
                           formStateValue,
                           handleChange,
                           label,
                           idName,
                           type
                         }) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)


  return (
    <>
      {type === 'password' ?
        <div>
          <Typography className={classes.labelTypography}>
            {label}
          </Typography>
          <TextField
            className={classes.textField}
            error={hasError(idName)}
            autoComplete={idName}
            fullWidth
            helperText={
              hasError(idName) ? formStateError : null
            }
            name={idName ? idName : 'name'}
            id={idName ? idName : 'name'}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={formStateValue || ''}
            InputLabelProps={{shrink: false}}
            variant="outlined"
            InputProps={{
              endAdornment : (
                <InputAdornment position={'end'}>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        :
        <div>
          <Typography className={classes.labelTypography}>
            {label}
          </Typography>
          <TextField
            className={classes.textField}
            error={hasError(idName)}
            autoComplete={idName}
            fullWidth
            helperText={
              hasError(idName) ? formStateError : null
            }
            name={idName ? idName : 'name'}
            id={idName ? idName : 'name'}
            onChange={handleChange}
            type={type ? type : "text"}
            value={formStateValue || ''}
            InputLabelProps={{shrink: false}}
            variant="outlined"/>
        </div>
      }
    </>
  )
}

CustomTextField.propTypes = {
  hasError: PropTypes.func,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  formStateValue: PropTypes.string,
  formStateError: PropTypes.string,
  idName: PropTypes.string
}

export default CustomTextField
