import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
}))

const Minimal = () => {

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Outlet/>
      </main>
    </div>
  )
}

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Minimal
