import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import * as Yup from 'yup';
import {makeStyles} from "@mui/styles";
import {useLocation} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Fehlerhafte E-Mail')
    .nullable(true)
    .required('E-Mail erforderlich'),
  code: Yup.string()
    .nullable(true)
    .min(6, 'Der Code besteht aus 6 Ziffern')
    .max(6, 'Der Code besteht aus 6 Ziffern')
    .required('Verifizierungscode erforderlich'),
  password: Yup.string()
    .required('Passwort erforderlich')
    .min(8, 'Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen erforderlich')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S*$/, 'Mindestens 8 Zeichen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen erforderlich'),
  confirm: Yup.string()
    .required(' ')
    .oneOf([Yup.ref('password'), null], 'Passwörter stimmen nicht überein'),
})

const useStyles = makeStyles(theme => ({
  resetRoot: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: '100vw',
  },
  formRoot: {
    height: '100%',
    maxWidth: '480px',
  },
  formBox: {
    flexGrow: 1,
    width: '100%'
  },
  headerBox: {
    maxWidth: '480px',
  },
  buttonBox: {
    alignSelf: "flex-end",
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.white,
  },
  labelTypography: {
    paddingTop: theme.spacing(1),
    fontSize: '12px',
    color: theme.palette.white,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      boxShadow: `0 4px 4px 0 rgba(0, 0, 0, 0.25)`,
      background: "rgb(255, 255, 255)",
      borderRadius: `8px`,
      width: '100%',
    },
  }
}))

const ResetPasswordForm = ({className, onSubmit}) => {

  const classes = useStyles()

  const navigate = useNavigate()
  const location = useLocation()
  const {enqueueSnackbar} = useSnackbar()

  const [email] = useState(
    new URLSearchParams(location.search).get("user"))
  const [code] = useState(
    new URLSearchParams(location.search).get("code"))

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const handleClickShowConfirm = () => setShowConfirm(!showConfirm)
  const handleMouseDownConfirm = () => setShowConfirm(!showConfirm)

  const formik = useFormik({
    initialValues: {
      email: email,
      code: code,
      password: '',
      confirm: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      enqueueSnackbar('Neues Passwort gespeichert.', {
        variant: "success"
      })
      navigate('/reset-password-completed')
    }
  })

  useEffect(() => {
    if (email) {
      const decodedMail = Buffer.from(email, "base64").toString()
      formik.setFieldValue('email', decodedMail)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  return (
    <div className={clsx(classes.resetRoot, className)}
    >
      <div className={classes.headerBox}>
        <Typography
          className={classes.title}
          variant="h4">
          PASSWORT VERGEBEN
        </Typography>
        <Typography
          className={classes.labelTypography}
          color="textSecondary"
          variant="body1"
          align={"justify"}
        >
          Um ein neues Passwort zu vergeben, gib bitte deine E-Mail-Adresse und
          den Verifizierungscode an. Diesen haben wir dir per E-Mail gesendet.
        </Typography>
      </div>
      <div className={classes.formBox}>
        <form className={classes.formRoot} onSubmit={formik.handleSubmit}>
          <Grid container className={classes.formRoot}>
            <Grid item xs={12}>

              <Typography
                className={classes.labelTypography}>
                E-Mail
              </Typography>
              <TextField
                className={classes.textField}
                InputLabelProps={{shrink: false}}
                fullWidth
                name="email"
                type={'email'}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
              />
              <Typography
                className={classes.labelTypography}>
                Verifizierungscode
              </Typography>
              <TextField
                className={classes.textField}
                InputLabelProps={{shrink: false}}
                fullWidth
                name="code"
                type={'text'}
                value={formik.values.code}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.code)}
                helperText={formik.errors.code}
              />
              <Typography
                className={classes.labelTypography}>
                Passwort
              </Typography>
              <TextField
                className={classes.textField}
                InputLabelProps={{shrink: false}}
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography
                className={classes.labelTypography}>
                Passwort wiederholen
              </Typography>
              <TextField
                className={classes.textField}
                InputLabelProps={{shrink: false}}
                fullWidth
                name="confirm"
                type={showConfirm ? 'text' : 'password'}
                value={formik.values.confirm}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.confirm)}
                helperText={formik.errors.confirm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <IconButton
                        onClick={handleClickShowConfirm}
                        onMouseDown={handleMouseDownConfirm}
                      >
                        {showConfirm ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonBox}>
              <Button
                id={"submit"}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                variant="contained">
                Passwort speichern
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  )
}

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default ResetPasswordForm
