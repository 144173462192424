import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import AuthGuard from "./AuthGuard/AuthGuard";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageRoot: {
    height: '100%',
    minHeight: '100%',
    maxWidth: '100%'
  },
}))

const Page = forwardRef(({
                           children,
                           title = '',
                           suppressAuthGuard = false,
                           requiredRole,
                           ...rest
                         }, ref) => {
  const classes = useStyles()
  return (
    <div
      className={classes.pageRoot}
      ref={ref}
      {...rest}>
      {
        suppressAuthGuard ? (

          <div
            className={classes.pageRoot}
            ref={ref}
            {...rest}
          >
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {children}
          </div>
        ) : (

          <div
            className={classes.pageRoot}
            ref={ref}
            {...rest}
          >
            <AuthGuard requiredRole={requiredRole}>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              {children}
            </AuthGuard>
          </div>
        )
      }
    </div>
  )
})

Page.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  viewName: PropTypes.string,
  pageTitle: PropTypes.string,
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
}

export default Page
