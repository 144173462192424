import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import {getMobileOperatingSystem} from "../../utils";
import SettingsPage from "../../components/SettingsPage";

const useStyles = makeStyles(theme => ({
  wrapperGrid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  formRoot: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "flex-start",
  },
  lowerGrid: {
    paddingLeft: theme.spacing(4),
  },
  numbers: {
    marginTop: '-3px'
  },
}))

const InstallationHelp = () => {
  const classes = useStyles()

  const system = getMobileOperatingSystem()

  const GENERAL_TEXT = 'Die Flotteladen App ist eine Webapplikation, die du mit einfachen Schritten auf deinem Homebildschirm speichern kannst.'
  const TEXT_INTRO = 'Gehe dazu wie folgt vor:'

  const FIRST_ANDROID_TEXT = 'Öffne das Menü am oberen rechten Bildschirmrand. (Drei Punkte)'
  const SECOND_ANDROID_TEXT = 'Wähle "App installieren". Die Flotteladen App erscheint auf deinem Homebildschirm.'

  const FIRST_IOS_TEXT = 'Wähle in der Toolbar "Teilen".'
  const SECOND_IOS_TEXT = 'Mit dem Button "Zum Home-Bildschirm" kannst du die App auf diesem installieren.'

  const THIRD_GLOBAL_TEXT = 'Fertig! Jetzt kannst du in der Flotteladen App Ladevorgänge an deinem LMS02 Cube starten und bisherige Ladevorgänge einsehen.'

  const FALLBACK_GLOBAL_TEXT = 'Die App wird leider nur auf iOS oder Android Geräten unterstützt.'

  return (
    <SettingsPage
      requiredRole={['FL_cp_user']}
      suppressAuthGuard
      navigatePath={-1}
      title={'Installationshilfe'}
    >
      <Grid container flexDirection={"row"} justifyContent={"space-between"}
            className={classes.wrapperGrid}>
        <Grid item xs={12}>
          <Grid container className={classes.formRoot}>
            <Grid item>
              <Typography variant={"body2"} align={"justify"}
                          >{GENERAL_TEXT}</Typography>
            </Grid>
            {(system === 'iOS' || system === 'Android') &&
              <Grid item alignSelf={"flex-start"}
                    style={{marginTop: '8px', marginBottom: '8px'}}>
                <Typography variant={"body2"} align={"justify"}
                            >{TEXT_INTRO}</Typography>
              </Grid>
            }
            {system === 'Android' &&
              <Grid item container alignItems={"flex-start"} spacing={2} className={classes.lowerGrid}>
                <Grid item xs={1} className={classes.numbers}>
                  <Typography variant={"body2"}  width={'10px'}
                              display={'inline'}>1.</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={"body2"} align={"justify"}
                              >{FIRST_ANDROID_TEXT}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.numbers}>
                  <Typography variant={"body2"}  width={'10px'}
                              display={'inline'}>2.</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={"body2"} align={"justify"}
                              >{SECOND_ANDROID_TEXT}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.numbers}>
                  <Typography variant={"body2"}  width={'10px'}
                              display={'inline'}>3.</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={"body2"} align={"justify"}
                              >{THIRD_GLOBAL_TEXT}</Typography>
                </Grid>
              </Grid>
            }
            {system === 'iOS' &&
              <Grid item container alignItems={"flex-start"} spacing={2} className={classes.lowerGrid}>
                <Grid item xs={1} className={classes.numbers}>
                  <Typography variant={"body2"}  width={'10px'}
                              display={'inline'}>1.</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={"body2"} align={"justify"}
                              >{FIRST_IOS_TEXT}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.numbers}>
                  <Typography variant={"body2"}  width={'10px'}
                              display={'inline'}>2.</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={"body2"} align={"justify"}
                              >{SECOND_IOS_TEXT}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.numbers}>
                  <Typography variant={"body2"}  width={'10px'}
                              display={'inline'}>3.</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={"body2"} align={"justify"}
                              >{THIRD_GLOBAL_TEXT}</Typography>
                </Grid>
              </Grid>
            }
            {(system !== 'iOS' && system !== 'Android') &&
              <Grid item className={classes.lowerGrid}>
                <Typography variant={"body2"} align={"justify"}
                            >{FALLBACK_GLOBAL_TEXT}</Typography>
              </Grid>
            }

          </Grid>
        </Grid>
      </Grid>


    </SettingsPage>
  )
}

export default InstallationHelp
