import PropTypes from 'prop-types'


const StatusTranslation = ({ status }) => {


  switch (status) {

    case 'CONNECTED':
    case 'CHARGING_SUSPENDED_EV':

      return 'Verbunden'

    case 'UNAUTHORIZED':

      return 'Bitte freigeben'

    case 'CHARGING':
    case 'CHARGING_STARTED_EV':

      return 'Wird geladen'

    case 'AVAILABLE':

      return 'Verfügbar'

    case 'CHARGING_COMPLETED':

      return 'Ladung beendet'

    case 'CHARGING_ALLOWED':

      return 'Ladefreigabe'

    case 'FAULTY_LOCKED':

      return 'Störung'

    case 'UNDEFINED':

      return 'nicht betriebsbereit'

    default:

      return '--'


  }

}


StatusTranslation.propTypes = {

  className: PropTypes.string,

}


export default StatusTranslation
