import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@mui/styles'
import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography
} from "@mui/material";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    height: '100%'
  },
  topBar: {
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: '#213C2C',
    height: '175px'
  },
  pageTitle: {
    color: "white",
    paddingLeft: theme.spacing(2),
    fontSize: '22px',
    fontWeight: 600,
  },
}))

const Topbar = props => {
  const {styleClass, ...rest} = props

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const getTitle = () => {
    if(location.pathname.includes('imprint')) {
      return 'IMPRESSUM'
    } else if (location.pathname.includes('agb')) {
      return 'AGBs'
    } else if (location.pathname.includes('datenschutz')) {
      return 'DATENSCHUTZ'
    }
  }

  return (
    <AppBar {...rest}
            elevation={0}
            position="fixed"
            className={classes.topBar}
            color={'transparent'}>
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.root}>
          <Grid item xs={12}/>
          <Grid item>

            <Button
              style={{justifyContent: "flex-start"}}
              color="inherit"
              onClick={() => navigate('/')}
            >
              <img alt="edit" src="/assets/FL_Logo_hell_weiss.svg"
                   style={{height: '22px', width: '183px'}}/>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.pageTitle}>{getTitle()}</Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  styleClass: PropTypes.string,
}

export default Topbar
