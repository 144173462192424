import React from 'react';
import {Box, Grid, Paper, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import SettingsPage from "../../components/SettingsPage";
import ChargingPointSvg from "../../components/ChargingPointSvg";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';


const useStyles = makeStyles(theme => ({
  containerRoot: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  firstHeaderSpacing: {
    paddingBottom: theme.spacing(1),
  },
  spacing: {
    paddingBottom: theme.spacing(2),
  },
  colourSpacing: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  unlockingSpacing: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
  },
  descriptionSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  lockIcon: {
    paddingTop: theme.spacing(0.5),
  },
  openLockIcon: {
    paddingTop: theme.spacing(1.5),
  },
  lockDescription: {
    paddingTop: theme.spacing(1),
  },

  headerSpacing: {
    paddingTop: theme.spacing(2)
  },
  mockBox: {
    height: '100px',
    width: '100px'
  },
  mockText: {
    zIndex: 0,
    position: 'absolute'
  },
  paper: {
    borderRadius: "20px",
    height: '138px',
    maxWidth: '138px',
    paddingTop: '35px'
  },
  customIconClass: {
    height: '70px',
    position: 'absolute',
    zIndex: 2
  },
  TextWithUnderline: {
    textDecoration: 'underline'
  },
  errorHandlingText: {
    paddingBottom: theme.spacing(2)
  }
}))


const Help = () => {

  const classes = useStyles()

  const TITEL = 'Hilfe'
  const FIRST_HEADER = 'Ladepunkte'
  const SUBTITLE = 'Übersicht über den Status des Ladepunkts.'

  const COLOUR_FIRST_ROW = 'Dunkelgrün  = Ladung beendet'
  const COLOUR_SECOND_ROW = 'Ladende Säule = Ladepunkt lädt'
  const COLOUR_THIRD_ROW = 'Grün        = Ladesäule ist verfügbar'
  const COLOUR_FOURTH_ROW = 'Gelb        = Warten auf Ladefreigabe'
  const COLOUR_FIFTH_ROW = 'Rot         = Störung'

  const ERROR_HANDLING_TEXT = 'Bei auftretenden Störungen wende dich bitte an den Betreiber der Ladestation.'

  const CP_DURATION_ICON = 'Zeigt die Dauer des aktuellen Ladevorgangs in Stunden und Minuten an.'

  return (
    <SettingsPage
      requiredRole={['FL_cp_user']}
      title={TITEL}
      navigatePath={-1}
    >
      <Grid
        container
        className={classes.containerRoot}
        spacing={2}
      >
        <Grid
          item
          container
          direction={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <Grid
            item
            className={classes.firstHeaderSpacing}
          >
            <Typography variant={'h4'}>{FIRST_HEADER}</Typography>
          </Grid>
          <Grid
            item
            className={classes.spacing}
          >
            <Typography variant={'body2'}>{SUBTITLE}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          spacing={2}
        >
          <Grid
            item
            container
            xs={7}
            className={classes.colourSpacing}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant={'body2'}>{COLOUR_FIRST_ROW}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant={'body2'}>{COLOUR_SECOND_ROW}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant={'body2'}>{COLOUR_THIRD_ROW}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant={'body2'}>{COLOUR_FOURTH_ROW}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant={'body2'}>{COLOUR_FIFTH_ROW}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={5}
          >
            <ChargingPointMockCard/>
          </Grid>
          <Grid
            item
            container
            direction={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <Grid
              item
              container
              xs={12}
              className={classes.unlockingSpacing}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant={'body2'}
                  className={classes.errorHandlingText}
                >
                  {ERROR_HANDLING_TEXT}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                className={classes.lockIcon}
              >
                <HourglassEmptyIcon/>
              </Grid>
              <Grid
                item
                xs={11}
              >
                <Typography
                  variant={'body2'}
                  className={classes.errorHandlingText}
                >
                  {CP_DURATION_ICON}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SettingsPage>
);
};

const ChargingPointMockCard = () =>
  {

    const classes = useStyles()

    const NAME = 'Name'
    const STATE = 'Status'

    return (
      <Paper
        elevation={8}
        variant={'elevation'}
        className={classes.paper}
      >
        <Grid
          container
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid
            container
            item
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            className={classes.mockBox}
          >
            <Grid>
              <Box marginBottom={16}>
                <ChargingPointSvg chargingStatus={'CHARGING'}
                                  customIconClass={classes.customIconClass}/>
              </Box>
            </Grid>
            <Grid
              item
              className={classes.mockText}
            >
              <Box marginTop={4} fontWeight={'bold'}>
                {NAME}
              </Box>
            </Grid>
            <Grid
              item
              className={classes.mockText}
            >
              <Box marginTop={8}>
                {STATE}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }

export default Help;
