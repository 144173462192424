import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { Topbar, Navbar } from "./components";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    paddingTop: 64,
  },
  content: {
    height: '100%',
  }
}))

const Main = () => {

  const classes = useStyles()
  const [isNavOpen, setNavOpen] = useState(false)

  return (
    <div className={classes.root}>
      <Topbar onSidebarOpen={() => setNavOpen(true)}/>
      <Navbar
        onClose={() => setNavOpen(false)}
        open={isNavOpen}
      />
      <main className={classes.content}>
        <Outlet/>
      </main>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Main
