import {makeStyles} from "@mui/styles";


const useStyles = makeStyles(theme => ({
  pageRoot: {
    background: 'linear-gradient(180deg, #00A652 0%, #2A3332 100%) !important',
    minHeight: '100%',
    minWidth: '100%',
    paddingTop: theme.spacing(1),
  },
}))

const AmperfiedPage = ({children}) => {
  const classes = useStyles()

  return (
    <div className={classes.pageRoot}>
      {children}
    </div>
  )
}

export default AmperfiedPage
