import React from 'react'
import {makeStyles} from "@mui/styles";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Grid, IconButton, Typography} from "@mui/material";
import {useNavigate} from "react-router";
import AuthGuard from "./AuthGuard/AuthGuard";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  pageRoot: {
    backgroundColor: theme.palette.white,
    minHeight: '100%',
    minWidth: '100%',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  iconButtonIcon: {
    alignSelf: "flex-start",
    marginTop: '2px',
    marginLeft: '-8px'
  },
  iconButtonTypography: {
    marginLeft: theme.spacing(2)
  }
}))

const SettingsPage = ({ requiredRole, children, title, navigatePath }) => {

  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.pageRoot}>
      <AuthGuard requiredRole={requiredRole}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <IconButton onClick={() => navigate(navigatePath)}>
            <KeyboardBackspaceIcon className={classes.iconButtonIcon}/>
            <Typography variant={"h3"} align={"left"} className={classes.iconButtonTypography}>
              {title}
            </Typography>
          </IconButton>
        </Grid>
        {children}
      </Grid>
      </AuthGuard>
    </div>
  )
}

SettingsPage.propTypes = {
  requiredRole: PropTypes.array,
  children: PropTypes.element,
  title: PropTypes.string,
  navigatePath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default SettingsPage
