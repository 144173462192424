import React, {useEffect, useState} from 'react'
import Page from "../../components/Page";
import {Grid, Typography} from "@mui/material";
import {withAuth} from "../../auth";
import {apiClient} from "../../apiClient";
import {useNavigate} from "react-router";
import ChargingPointListCard from "../../components/ChargingPointListCard";

const ChargingPoint = () => {
  const [chargingSites, setChargingSites] = useState([])
  const [noSites, setNoSites] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    apiClient.get('/api/app/clients')
      .then((r) => {
        setNoSites(false)
        setChargingSites(r.data)
        // redirect zur ersten Ladepunkt des ersten Systems falls nur insgesamt ein Ladepunkt vorhanden ist.
        if (r.data.length === 1 && r.data[0].chargingPoints && r.data[0].chargingPoints.length === 1) {
          navigate(`${r.data[0].clientId}/point/${r.data[0].chargingPoints[0].id}`)
        }
      })
      .catch((e) => {
        setNoSites(true)
        console.log('error ' + e)
      })
  }

  return (
    <Page title={'Flotteladen Fahrer App'}
          requiredRole={['FL_support', 'FL_cp_user']}>
      <Grid container direction={"column"} alignItems={"center"}
            justifyContent={"center"}>
        {noSites ? (
            <Grid item xs={12}>
              <Typography variant={"h5"} align={"center"} color={"white"}>
                Keine zugewiesenen Ladepunkte gefunden
              </Typography>
            </Grid>
          ) : (
            chargingSites.map((site) => (
              <Grid item container spacing={2} padding={1} key={site.name}
                    justifyContent={"center"}>
                <Grid item xs={12} key={site.name}>
                  <Typography key={site.name} variant={"h5"} align={"center"}
                              color={"white"}>
                    {site.name}
                  </Typography>
                </Grid>
                {
                  site.chargingPoints.map((point) => (
                    <Grid item key={point.id}>
                      <ChargingPointListCard key={point.id}
                                             chargingPoint={point}
                                             onClick={() => navigate(`${site.clientId}/point/${point.id}`)}/>
                    </Grid>
                  ))}
              </Grid>
            ))
          )}
      </Grid>
    </Page>
  )
}

export default withAuth(ChargingPoint)
