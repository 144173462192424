import { createTheme } from "@mui/material";

import 'typeface-work-sans'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'

const theme = createTheme({
  palette,
  typography,
  components: overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})

export default theme
