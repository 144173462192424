import React, {useEffect, useState} from 'react'
import {makeStyles} from '@mui/styles'
import appLicense from '../../texts/appLicense.txt'
import SettingsPage from "../../components/SettingsPage";
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles(() => ({
  pageRoot: {
    paddingLeft: '8px',
    paddingRight: '8px',
    textOverflow: "clip",
    overflow: "auto"
  }
}))

const Licenses = () => {
  const classes = useStyles()

  const [frontendText, setFrontendText] = useState('')

  useEffect(() => {
    fetch(appLicense)
      .then(res => res.text())
      .then(text => {
          setFrontendText(text)
        }
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingsPage
      requiredRole={['FL_cp_user']}
      title={'Lizenzen'}
      navigatePath={-1}>
      <div className={classes.pageRoot}>
        <ReactMarkdown className={classes.pageRoot} children={frontendText}/>
      </div>
    </SettingsPage>
  )
}

Licenses.propTypes = {}

export default Licenses
