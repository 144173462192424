import React, {useEffect, useState} from 'react'
import {makeStyles} from "@mui/styles";
import {
  Avatar,
  Grid,
  List, ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import {useNavigate} from "react-router";
import SettingsPage from "../../components/SettingsPage";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {apiClient} from "../../apiClient";
import HelpIcon from '@mui/icons-material/Help';

const useStyles = makeStyles(theme => ({
  pageRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  userContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  userTypography: {
    paddingTop: theme.spacing(1)
  },
  listAvatarContainer: {
    width: theme.spacing(2)
  },
  itemContainerBox: {
    marginBottom: theme.spacing(1)
  },
  itemContainer: {
    width: '100%'
  },
  forwardIcon: {
    alignSelf: "flex-end"
  },
  itemTextContainer:{
    marginTop: '6px'
  },
  itemText: {
    marginLeft: '-18px'
  },
  itemTextIcon: {
    marginTop: '2px'
  }
}))

const Settings = () => {

  const classes = useStyles()
  const navigate = useNavigate()
  const USER_API = '/api/app/user'
  const [userHandle, setUserHandle] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userName, setUserName] = useState('')

  const fetchData = () => {
    apiClient.get(USER_API)
      .then((response) => {
        setUserEmail(response.data.email)
        setUserHandle(response.data.firstName[0].toUpperCase() + response.data.lastName[0].toUpperCase())
        setUserName(response.data.firstName + ' ' + response.data.lastName)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <SettingsPage
      requiredRole={['FL_cp_user']}
      title={'Einstellungen'}
      navigatePath={-1}
    >
      <Grid container className={classes.pageRoot}>
        <div className={classes.userContainer}>
            <Avatar className={classes.avatar}>
              {userHandle}
            </Avatar>
          <div>
            <Typography variant={"h4"} className={classes.userTypography}>
              {userName}
            </Typography>
            <Typography variant={"body2"} className={classes.userTypography}>
              {userEmail}
            </Typography>
          </div>

        </div>
        <Grid item className={classes.itemContainer}>
          <List className={classes.itemContainer}>
            <ListItem
              className={classes.itemContainerBox}
              alignItems={"flex-start"}
              disablePadding
              onClick={() => navigate('./appSettings')}
            >
              <ListItemAvatar sx={{ width: '20px'}}>
                  <DirectionsCarIcon/>
              </ListItemAvatar>
              <ListItemText className={classes.itemContainer}>
                <Grid container className={classes.itemTextContainer}>
                  <Grid item xs={11}>
                    <Typography variant={'body1'} fontSize={'16px'} className={classes.itemText}>
                      Ladefreigabe
                    </Typography>

                  </Grid>
                  <Grid item xs={1}>
                    <ArrowForwardIosIcon className={classes.itemTextIcon} fontSize={'8px'}/>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>

            <ListItem
              className={classes.itemContainerBox}
              alignItems={"flex-start"}
              disablePadding
              onClick={() => navigate('/help')}
            >
              <ListItemAvatar sx={{ width: '20px'}}>
                <HelpIcon/>
              </ListItemAvatar>
              <ListItemText>
                <Grid container className={classes.itemTextContainer}>
                  <Grid item xs={11}>
                    <Typography variant={'body1'} fontSize={'16px'} className={classes.itemText}>
                      Hilfe
                    </Typography>

                  </Grid>
                  <Grid item xs={1}>
                    <ArrowForwardIosIcon className={classes.itemTextIcon} fontSize={'8px'}/>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>

          </List>
        </Grid>
      </Grid>
    </SettingsPage>
  )
}

export default Settings
