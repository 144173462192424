import React from 'react'
import {withAuth} from "../../auth";
import {makeStyles} from "@mui/styles";
import NewPasswordForm from "../../components/NewPasswordForm";
import Page from "../../components/Page";

const useStyles = makeStyles(theme => ({
  grid: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
  },
  contentBody: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))

const NewPassword = ({ auth }) => {
  const classes = useStyles()
  const { signedIn, user, error, completeNewPassword } = auth

  const handleSubmit = (values) => {
    completeNewPassword(user, values.password)
  }

  return (
    <Page title={'Flotteladen Fahrer App'} suppressAuthGuard>
      <div
        className={classes.grid}
      >
        {signedIn && (
          <div className={classes.contentBody}>
            <NewPasswordForm className={classes.form} onSubmit={handleSubmit} error={error}/>
          </div>
        )}
      </div>
    </Page>
  )
}

export default withAuth(NewPassword)
