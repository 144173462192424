import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import AlarmIcon from '@mui/icons-material/Alarm';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  toggleButtons: {
    borderRadius: '20px',
    width: '118px',
    height: '40px',
    fontSize: '12px',
    '&.Mui-selected': {
      color: '#FFFFFF',
      backgroundColor: '#B2DF8A'
    },
    '&.Mui-selected:hover': {
      color: '#FFFFFF',
      backgroundColor: '#B2DF8A'
    }
  },
}))

const ChargingModeToggle = ({ chargingOption, setChargingOption }) => {
  const classes = useStyles()

  return (
    <ToggleButtonGroup
      value={chargingOption}
      onChange={setChargingOption}
      color={"primary"}
      exclusive
    >
      <ToggleButton value="FAST" className={classes.toggleButtons}>
        <AlarmIcon sx={{ paddingRight: '4px' }}/>
          Komfort
      </ToggleButton>

      <ToggleButton value="GREEN" className={classes.toggleButtons}>
        <WbSunnyIcon sx={{ paddingRight: '4px' }}/>
          Nachhaltig
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default ChargingModeToggle
