import React from 'react'
import PropTypes from 'prop-types'
import {Field, Form, Formik} from 'formik'
import validate from 'validate.js'
import {TextField} from 'formik-material-ui'
import {VALIDATION_SCHEMA_EMAIL} from '../constants'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router'
import {Button, Grid, Typography} from "@mui/material";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";

const schema = {
  email: VALIDATION_SCHEMA_EMAIL,
}

const useStyles = makeStyles(theme => ({
  emailRoot: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    color: theme.palette.white,
  },
  formRoot: {
    height: '100%',
  },
  formBox: {
    flexGrow: 1,
    maxWidth: '480px',
  },
  titleBox: {
    minHeight: '20%',
    maxWidth: '480px',
  },
  formContainer: {
    height: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  labelTypography: {
    paddingTop: theme.spacing(1),
    fontSize: '12px',
    color: theme.palette.white,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      boxShadow: `0 4px 4px 0 rgba(0, 0, 0, 0.25)`,
      background: "rgb(255, 255, 255)",
      borderRadius: `8px`,
    },
  },
  buttonBox: {
    alignSelf: "flex-end",
  }
}))

const EmailForm = ({ className, onSubmit, ...rest}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()

  return (
    <Grid {...rest}
          className={clsx(classes.emailRoot, className)} container spacing={2}
    >
      <Grid item className={classes.titleBox}>
        <Typography
          className={classes.title}
          variant="h4">
          PASSWORT VERGESSEN
        </Typography>
        <Typography
          className={classes.labelTypography} align={"justify"}>
          Gib die E-Mail-Adresse der Erstregistrierung ein. Wir senden dir anschließend einen Verifizierungscode zum Zurücksetzen deines Passwortes zu.
        </Typography>
      </Grid>
      <Grid item className={classes.formBox}>
        <Formik
          initialValues={{
            email: '',
          }}
          validate={values => {
            const errors = validate(values, schema)
            return errors && errors.email ? {email: errors.email[0]} : undefined
          }}
          onSubmit={values => {
            onSubmit(values);
            enqueueSnackbar('Verifizierungscode versendet.', {
              variant: "success"
            })
            navigate('/confirm-password')
          }
          }>
          {({isSubmitting, isValid}) => (
            <Form className={classes.formRoot}>
              <Grid container className={classes.formContainer}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.labelTypography}>
                    E-Mail
                  </Typography>
                  <Field
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{shrink: false}}
                    name="email"
                    type="email"
                    component={TextField}/>
                </Grid>
                <Grid item xs={12} className={classes.buttonBox}>
                  <Button
                    id={"submit"}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    variant="contained">
                    Verifizierungscode zusenden
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

EmailForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default EmailForm
