import SettingsPage from "../../components/SettingsPage";
import {Grid, Link, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  containerRoot: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  rowSpacing: {
    marginBottom: theme.spacing(3),
  },
}))


const ImprintInfo = () => {
  const classes = useStyles()

  const TITLE = 'Impressum'

  const ADDRESS_LINE_1 = 'Meser & Hirt UG (haftungsbeschränkt)'
  const ADDRESS_LINE_2 = 'Kaltbrunner Straße 24b'
  const ADDRESS_LINE_3 = 'D-78476 Allensbach'

  const PHONE = 'Telefon +49-(0) 7533 80 30 595'
  const MAIL = 'E-Mail: info@flotteladen.de'
  const INTERNET = 'Internet: '

  const TAXES = 'Umsatzsteuer-Identiﬁkationsnummer gemäß §27a Umsatzsteuergesetz: DE367933461'

  const CEO = 'Amtsgericht Freiburg i. Br. HRB 731004 Geschäftsführer: Alexander Hirt, Markus Meser'

  const RESPONSIBILITY = 'Inhaltlich Verantwortlicher gemäß § 10, Abs. 3 MDStV: Alexander Hirt, Kaltbrunner Straße 24b, D-78476 Allensbach'

  return (
    <SettingsPage
      title={TITLE}
      navigatePath={-1}>
      <Grid
        container
        className={classes.containerRoot}
        spacing={2}
      >
        <Grid
          item
        >
          <Typography variant={'body1'}>{ADDRESS_LINE_1}</Typography>
          <Typography variant={'body1'}>{ADDRESS_LINE_2}</Typography>
          <Typography variant={'body1'}
                      className={classes.rowSpacing}>{ADDRESS_LINE_3}</Typography>

          <Typography variant={'body1'}>{PHONE}</Typography>
          <Typography variant={'body1'}>{MAIL}</Typography>
          <Typography variant={'body1'}
                      className={classes.rowSpacing}>{INTERNET}
            <Link
              component="a"
              href="https://www.flotteladen.de/"
              target="_blank">
              www.flotteladen.de
            </Link>
            {' '}
          </Typography>

          <Typography variant={'body1'} className={classes.rowSpacing}>{TAXES}</Typography>

          <Typography variant={'body1'} className={classes.rowSpacing}>{CEO}</Typography>

          <Typography variant={'body1'} className={classes.rowSpacing}>{RESPONSIBILITY}</Typography>
        </Grid>
      </Grid>

    </SettingsPage>
  )

}

export default ImprintInfo
