import axios from 'axios'
import { Auth } from 'aws-amplify'

async function getCognitoHeaders () {
  return ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  })
}

const apiAddress = process.env.REACT_APP_BACKEND_API_ADDRESS

class ApiClient {

  constructor (apiName, getHeaders) {
    this.apiName = apiName
    this.getHeaders = getHeaders
  }

  async get (url, queryParams = {}) {
    const init = {
      headers: await this.getHeaders(),
      params: queryParams,
    }
    return await axios.get(this.apiName + url, init)
  }

  async post (url, body) {
    const init = {
      headers: await this.getHeaders(),
    }
    return await axios.post(this.apiName + url, body, init)
  }

  async put (url, body) {
    const init = {
      headers: await this.getHeaders(),
    }
    return await axios.put(this.apiName + url, body, init)
  }

  async patch (url, body) {
    const init = {
      headers: await this.getHeaders(),
    }
    return await axios.patch(this.apiName + url, body, init)
  }

  async delete (url) {
    const init = {
      headers: await this.getHeaders(),
    }
    return await axios.delete(this.apiName + url, init)
  }

}

export const apiClient = new ApiClient(apiAddress, getCognitoHeaders)
