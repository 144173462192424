import React from 'react'
import {withAuth} from "../../auth";
import {makeStyles} from "@mui/styles";
import ResetPasswordForm from "../../components/ResetPasswordForm";

const useStyles = makeStyles(theme => ({
  pageRoot: {
    height: '100%',
  },
  grid: {
    height: '100%',
    paddingTop: theme.spacing(8)
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))

const ResetPasswordConfirm = ({ auth: { error, completePasswordReset } }) => {

  const classes = useStyles()

  const handleSubmit = (values) => {
    completePasswordReset(values.email, values.code, values.password)
  }

  return (
    <div className={classes.pageRoot}>
      <div
        className={classes.grid}>
        <div className={classes.contentBody}>
          <ResetPasswordForm className={classes.form} onSubmit={handleSubmit} error={error}/>
        </div>
      </div>
    </div>
  )
}

export default withAuth(ResetPasswordConfirm)
