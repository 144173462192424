import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    width: '82px'
  },
  nameTypography: {
    fontSize: '10px',
    color: '#007B43'
  },
  valueTypography: {
    fontSize: '18px',
    fontWeight: 500
  },
  unitTypography: {
    fontSize: '12px',
    fontWeight: 500,
    marginLeft: '2px'
  }
}))


const ChargingValueDisplay = ({ value, name, unit}) => {
  const classes = useStyles()
  return (
    <Grid container direction={"column"} className={classes.gridContainer}>
      <Grid item>
        <Typography className={classes.nameTypography}>
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography display={'inline'} className={classes.valueTypography}>
          {value}
        </Typography>
        <Typography display={'inline'} className={classes.unitTypography}>
          {unit}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ChargingValueDisplay
