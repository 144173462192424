import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import AppsIcon from '@mui/icons-material/Apps';
import NavItem from './NavItem'
import {makeStyles} from "@mui/styles";
import {
  alpha,
  Box,
  Drawer,
  Link,
  List,
  ListItem,
  Typography
} from "@mui/material";
import SignOutButton from "../../../../components/SignOutButton";
import InputIcon from "@mui/icons-material/Input";
import HelpIcon from "@mui/icons-material/Help";
import {apiClient} from "../../../../apiClient";
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import {useParams} from "react-router";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 240,
    background: theme.palette.primary.gradient,
    border: "none",
    paddingTop: theme.spacing(3)
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%'
  },
  button: {
    color: alpha(theme.palette.primary.contrastText, 0.5),
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.contrastText, 0.4),
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.contrastText,
      },
    }
  },
  icon: {
    color: alpha(theme.palette.primary.contrastText, 0.5),
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
}))

const NavBar = ({onClose, open}) => {
  const classes = useStyles()
  const location = useLocation()
  const {clientId} = useParams()
  const CHARGE_ADVICE_URL = '/api/app/client/{clientId}/ems/configuration/charge-advice'

  const [items, setItems] = useState([
    {
      href: 'settings',
      icon: SettingsIcon,
      title: 'Einstellungen'
    },
    {
      href: '/help',
      icon: HelpIcon,
      title: 'Hilfe'
    },
    {
      href: '/furtherInstallation',
      icon: InstallMobileIcon,
      title: 'Installationshilfe'
    },
    {
      href: '/privacyFlotteladen',
      icon: SecurityIcon,
      title: 'Datenschutz'
    },
    {
      href: '/feedback',
      icon: FeedbackIcon,
      title: 'Feedback'
    }
  ])

  useEffect(() => {
    if(clientId) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    let adviceURL = CHARGE_ADVICE_URL.replace(/{clientId}/g, clientId)
    apiClient.get(adviceURL)
      .then((response) => {
        apiClient.get('/api/app/clients')
          .then((r) => {
            let chargingPoints = 0
            r.data.forEach((station) => {
              chargingPoints += station.chargingPoints.length
            })
            if (response.data.enabled) {

              if (r.data && chargingPoints > 1 && location.pathname !== '/app') {
                setItems([
                  {
                    href: '/app',
                    icon: AppsIcon,
                    title: 'Ladestationen',
                  },
                  {
                    href: 'settings',
                    icon: SettingsIcon,
                    title: 'Einstellungen'
                  },
                  {
                    href: '/help',
                    icon: HelpIcon,
                    title: 'Hilfe'
                  },
                  {
                    href: '/furtherInstallation',
                    icon: InstallMobileIcon,
                    title: 'Installationshilfe'
                  },
                  {
                    href: '/privacyFlotteladen',
                    icon: SecurityIcon,
                    title: 'Datenschutz'
                  },
                  {
                    href: '/feedback',
                    icon: FeedbackIcon,
                    title: 'Feedback'
                  }
                ])
              } else {
                setItems([
                  {
                    href: 'settings',
                    icon: SettingsIcon,
                    title: 'Einstellungen'
                  },
                  {
                    href: '/help',
                    icon: HelpIcon,
                    title: 'Hilfe'
                  },
                  {
                    href: '/furtherInstallation',
                    icon: InstallMobileIcon,
                    title: 'Installationshilfe'
                  },
                  {
                    href: '/privacyFlotteladen',
                    icon: SecurityIcon,
                    title: 'Datenschutz'
                  },
                  {
                    href: '/feedback',
                    icon: FeedbackIcon,
                    title: 'Feedback'
                  }
                ])
              }
            } else {
              if (r.data && chargingPoints > 1 && location.pathname !== '/app') {
                setItems([
                  {
                    href: '/app',
                    icon: AppsIcon,
                    title: 'Ladestationen',
                  },
                  {
                    href: '/help',
                    icon: HelpIcon,
                    title: 'Hilfe'
                  },
                  {
                    href: '/furtherInstallation',
                    icon: InstallMobileIcon,
                    title: 'Installationshilfe'
                  },
                  {
                    href: '/privacyFlotteladen',
                    icon: SecurityIcon,
                    title: 'Datenschutz'
                  },
                  {
                    href: '/feedback',
                    icon: FeedbackIcon,
                    title: 'Feedback'
                  }
                ])
              } else {
                setItems([
                  {
                    href: '/help',
                    icon: HelpIcon,
                    title: 'Hilfe'
                  },
                  {
                    href: '/furtherInstallation',
                    icon: InstallMobileIcon,
                    title: 'Installationshilfe'
                  },
                  {
                    href: '/privacyFlotteladen',
                    icon: SecurityIcon,
                    title: 'Datenschutz'
                  },
                  {
                    href: '/feedback',
                    icon: FeedbackIcon,
                    title: 'Feedback'
                  }
                ])
              }

            }
          })
          .catch((e) => {
            console.log('error ' + e)
          })
      })
      .catch((e) => {
        console.log('error ' + e)
      })
  }

  useEffect(() => {
    if (open && onClose) {
      onClose()
    }
    if (location.pathname === '/app') {
      setItems([])
    } else {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column">

      <Box>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1}/>
      <Box>
        <List>
          <ListItem className={classes.item}>
            <SignOutButton className={classes.button}>
              <div className={classes.icon}><InputIcon size={20}/></div>
              <span className={classes.title}>
                Abmelden
              </span>
            </SignOutButton>
          </ListItem>
        </List>
      </Box>
      <Box display="flex" pl={2} pb={1}>
        <Typography variant="body1" color={"white"}>
          <Link
            color={"white"}
            component="a"
            href="https://www.flotteladen.de/"
            target="_blank">
            Flotteladen
          </Link>
          . 2022
          {' '}
          <Link
            color={"white"}
            href="/imprintFlotteladen">
            Impressum
          </Link>
          {' '}
          <Link
            color={"white"}
            href="/licenses">
            Lizenzen
          </Link>
        </Typography>
      </Box>
    </Box>
  )

  return (
    <>
      <Drawer
        anchor="right"
        classes={{paper: classes.mobileDrawer}}
        onClose={onClose}
        open={open}
        variant="temporary">
        {content}
      </Drawer>
    </>
  )
}

NavBar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  auth: PropTypes.object
}

NavBar.defaultProps = {
  onClose: () => {
  },
  open: false,
}

export default NavBar
