import {colors} from "@mui/material";

const white = '#FFFFFF'
const black = '#000000'

const palette = {
  black,
  white,
  primary: {
    light: '#53be70',
    main: '#3eb660',
    dark: '#30ad51',
    contrastText: white,
    //gradient being used for background is in MyCssBaseline !!!
    gradient: 'linear-gradient(180deg, #53be70 20%, #30ad51 100%) !important',
    amperfiedGradient: 'linear-gradient(180deg, #000000 0%, #2A3332 100%) !important',
    unselectedText: '#86BFA3'
  },
  secondary: {
    light: '#8b959a',
    main: '#5e676c',
    dark: '#343d41',
    contrastText: white,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[700],
    light: colors.red[400],
  },
  text: {
    primary: '#5e676c',
    secondary: '#5e676c',
    link: colors.blue[600],
  },
  background: {
    //gradient being used as background is in MyCssBaseline !!!
    gradient: 'linear-gradient(180deg, #53be70 20%, #30ad51 100%) !important',
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  disable: '#B9B9B9'
}

export default palette
