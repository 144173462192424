// eslint-disable-next-line max-classes-per-file
import React from 'react'
import { Auth } from 'aws-amplify'
import { some } from 'lodash'

function extractRoles (user) {
  return user?.getSignInUserSession()
    ?.getAccessToken()
    ?.decodePayload()['cognito:groups'] || []
}

function isNewPasswordRequired (user) {
  return user.challengeName === 'NEW_PASSWORD_REQUIRED' || user.challengeName === 'RESET_REQUIRED'
}

const initialAuthState = {
  signedIn: false,
  challenge: null,
  newPasswordRequired: false,
  user: null,
  roles: [],
  error: null,
  authenticating: true,
}

const AuthContext = React.createContext()

class AuthProvider extends React.Component {

  constructor () {
    super()
    this.state = {
      ...initialAuthState,
      login: this.login,
      logout: this.logout,
      initPasswordReset: this.initPasswordReset,
      completePasswordReset: this.completePasswordReset,
      completeNewPassword: this.completeNewPassword,
      hasRequiredRole: this.hasRequiredRole,
    }
  }

  componentDidMount () {
    this.getCurrentUser()
  }

  setAuthenticationPending = () => {
    this.setState({
      signedIn: false,
      authenticating: true,
    })
  }

  handleAuthenticationCompleted = (user) => {
    this.setState({
      user,
      roles: extractRoles(user),
      signedIn: true,
      challenge: user.challengeName || null,
      newPasswordRequired: isNewPasswordRequired(user),
      error: null,
      authenticating: false,
    })
  }

  handleAuthenticationRejected = (error) => {
    this.setState({
      ...initialAuthState,
      error,
      authenticating: false,
    })
  }

  handleRejected = (error) => {
    this.setState({
      error,
    })
  }

  getCurrentUser = () => {
    this.setAuthenticationPending()
    Auth.currentAuthenticatedUser()
      .then(this.handleAuthenticationCompleted, this.handleAuthenticationRejected)
  }

  login = (username, password) => {
    this.setAuthenticationPending()
    Auth.signIn(username, password)
      .then(this.handleAuthenticationCompleted, this.handleAuthenticationRejected)
      .catch(() => {
        this.props.enqueueSnackbar('Bei der Authentifizierung ist etwas schief gelaufen.', {
          variant: 'error',
        })
      })
  }

  logout = () => {
    this.setAuthenticationPending()
    Auth.signOut()
      .then(() => this.setState({
        ...initialAuthState,
        authenticating: false,
      }), this.handleAuthenticationRejected)
      .catch(() => {
        this.props.enqueueSnackbar('Bei der Authentifizierung ist etwas schief gelaufen.', {
          variant: 'error',
        })
      })

  }

  initPasswordReset = (username) => {
    Auth.forgotPassword(username)
      .then(() =>
        this.props.navigate('/confirm-password'),
      this.handleRejected)
      .catch(() => {
        this.props.enqueueSnackbar('Bei der Authentifizierung ist etwas schief gelaufen.', {
          variant: 'error',
        })
      })

  }

  completePasswordReset = (username, code, newPassword) => {
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => this.props.navigate('/'), this.handleRejected)
      .catch(() => {
        this.props.enqueueSnackbar('Bei der Authentifizierung ist etwas schief gelaufen.', {
          variant: 'error',
        })
      })

  }

  completeNewPassword = (user, newPassword) => {
    Auth.completeNewPassword(user, newPassword)
      .then((usr) => {
        this.handleAuthenticationCompleted(usr)
        this.props.navigate('/installation')
      }, this.handleRejected)
      .catch(() => {
        this.props.enqueueSnackbar('Bei der Authentifizierung ist etwas schief gelaufen.', {
          variant: 'error',
        })
      })
  }

  hasRequiredRole = (requiredRoles) => {

    const { user, roles } = this.state

    if (!user) {
      return false
    }

    if (requiredRoles === undefined || requiredRoles === null) {
      return true
    }

    if (Array.isArray(requiredRoles)) {
      return some(roles, (role) => requiredRoles.includes(role))
    }

    if (typeof requiredRoles === 'string' || requiredRoles instanceof String) {
      return roles.includes(requiredRoles)
    }

    return false
  }

  render () {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export function withAuth (WrappedComponent) {
  return class extends React.Component {
    render () {
      // Wraps the input component in a container
      return (
        <AuthContext.Consumer>
          {(context) => <WrappedComponent {...this.props} auth={context}/>}
        </AuthContext.Consumer>
      )
    }
  }
}

export default AuthProvider
