import {useSnackbar} from "notistack";
import { useNavigate, useRoutes } from "react-router-dom";
import routes from "./Routes";
import {AuthProvider} from "./auth";
import {Amplify} from "aws-amplify";
import {CookieConsent} from "react-cookie-consent";

const userPoolId = process.env.REACT_APP_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolWebClientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

function App() {
  const routing = useRoutes(routes)
  const navigation = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  return (
      <AuthProvider navigate={navigation} enqueueSnackbar={enqueueSnackbar}>
        <CookieConsent
          buttonStyle={{ backgroundColor: "#FFFFFF" }}
          buttonText="Akzeptieren"
        >
          Diese Seite nutzt technische Cookies.
        </CookieConsent>
          {routing}
      </AuthProvider>
  );
}

export default App;
