import React, {useEffect, useState} from 'react'
import {makeStyles} from "@mui/styles";
import {useParams} from "react-router";
import SettingsPage from "../../components/SettingsPage";
import {apiClient} from "../../apiClient";
import {Grid, MenuItem, TextField, Typography} from "@mui/material"
import {useSnackbar} from "notistack";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles(theme => ({
  containerRoot: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3)
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '11px',
    fontWeight: 300,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    fontFamily: 'Work Sans',
  },
  unlockingBox: {
    marginTop: theme.spacing(2)
  },
  switchBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  }
}))

const chargingModeOptions = [
  {
    value: 'FAST',
    label: 'Komfort'
  },
  {
    value: 'GREEN',
    label: 'Nachhaltig'
  },
  {
    value: 'LAST_USED',
    label: 'Zuletzt verwendet'
  }
]


const AppSettings = () => {
  const classes = useStyles()

  const { clientId, pointId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [chargeMode, setChargeMode] = useState("GREEN")
  const POINT_API = '/api/app/client/{clientId}/chargingpoints/{chargingPointId}'
  const CHARGEMODE_API = '/api/app/client/{clientId}/chargingpoints/{chargingPointId}/default-charge-mode'

  const DESCRIPTION_FIRST_LINE = 'Wähle hier deinen präferierten Lademodus.'
  const DESCRIPTION_SECOND_LINE = 'Beim Komfort-Laden lädst du mit der voll zu Verfügung stehenden Leistung.'
  const DESCRIPTION_THIRD_LINE = 'Nachhaltig-Laden ermöglicht dir kostenoptimiert, zu günstigeren Strompreisen zu laden.'
  const DESCRIPTION_FOURTH_LINE = 'Zuletzt verwendet übernimmt den Lademodus deines vorherigen Ladevorgangs. Über deine Startseite kannst du den Lademodus jederzeit anpassen.'

  const fetchData = () => {
    let url = POINT_API.replace(/{clientId}/g, clientId)
    url = url.replace(/{chargingPointId}/g, pointId)
    apiClient.get(url)
      .then((res) => {
        setChargeMode(res.data.defaultChargeMode)
      })
  }

  const handleChargeModeChange = (event) => {
    const newChargeMode = event.target.value
    let url = CHARGEMODE_API.replace(/{clientId}/g, clientId)
    url = url.replace(/{chargingPointId}/g, pointId)
    apiClient.put(url, newChargeMode)
      .then(() => {
        enqueueSnackbar('Die Änderungen wurden erfolgreich übernommen.', {
          variant: 'success',
        })
        fetchData()
      })
      .catch(() => enqueueSnackbar('Die Änderungen können nicht gespeichert werden, bitte prüfen Sie ihre Eingabe.', {
        variant: 'error',
      }))
  };

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingsPage
      requiredRole={['FL_cp_user']}
      title={'Ladefreigabe'}
      navigatePath={-1}
    >
      <Grid container className={classes.containerRoot}>
        <Grid item container xs={12}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center'}}>
            <Typography>
              Standard Lademodus
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              select
              variant={"filled"}
              InputProps={{
                disableUnderline: true,
                hiddenLabel: true
              }}
              onChange={handleChargeModeChange}
              value={chargeMode ? chargeMode : 'GREEN'}
            >
              {chargingModeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <HelpOutlineIcon fontSize={"small"} sx={{marginTop: '12px'}}/>
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.description}>
            {DESCRIPTION_FIRST_LINE}
          </Typography>
          <Typography className={classes.description}>
            {DESCRIPTION_SECOND_LINE}
          </Typography>
          <Typography className={classes.description}>
            {DESCRIPTION_THIRD_LINE}
          </Typography>
          <Typography className={classes.description}>
            {DESCRIPTION_FOURTH_LINE}
          </Typography>
        </Grid>
      </Grid>
    </SettingsPage>
  )
}

export default AppSettings
