import React, {useState} from 'react'
import PropTypes from 'prop-types'
import EvStationIcon from '@mui/icons-material/EvStation';
import LockIcon from '@mui/icons-material/Lock';
import Poller from "./Poller";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  lockBox: {
    display: "flex",
    flexDirection: "column",
    marginBottom: '5px'
  },
  lockedStation: {
    fontSize: '150px',
    marginBottom: '-23px'
  },
  lock: {
    marginBottom: '4px'
  },
  imageBox: {
    height: '100%',
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  }
}))

const ChargingPointSvg = ({
                            chargingStatus,
                            fontSize,
                            customIconClass,
                            lockMarginBottom,
                            lockMarginLeft
                          }) => {
  const classes = useStyles()

  const [icon, setIcon] = useState(
    <img
      className={customIconClass}
      alt="Logo"
      src="/assets/chargingstation_loading_icons/Ladesaeule1.svg"
    />)

  const [iconCounter, setIconCounter] = useState(1)

  const setChargingIcon = () => {
    switch (iconCounter) {
      case 0:
        setIconCounter(1)
        setIcon(
          <img
            className={customIconClass}
            alt="charging"
            src="/assets/chargingstation_loading_icons/Ladesaeule1.svg"/>
        )
        return
      case 1:
        setIconCounter(2)
        setIcon(
          <img
            className={customIconClass}
            alt="charging"
            src="/assets/chargingstation_loading_icons/Ladesaeule2.svg"/>
        )
        return
      case 2:
        setIconCounter(3)
        setIcon(
          <img
            className={customIconClass}
            alt="charging"
            src="/assets/chargingstation_loading_icons/Ladesaeule3.svg"/>
        )
        return
      case 3:
        setIconCounter(0)
        setIcon(
          <img
            className={customIconClass}
            alt="charging"
            src="/assets/chargingstation_loading_icons/Ladesaeule4.svg"/>
        )
        return
      default:
        setIcon(<EvStationIcon
          style={{fill: '#30AD51', fontSize: fontSize}}
        />)
        return
    }
  }

  if (chargingStatus === null) {
    return (
      <div className={classes.imageBox}>
        <EvStationIcon
          style={{fill: '#B9B9B9', fontSize: fontSize}}
        />
      </div>
    )
  }

  switch (chargingStatus) {
    case 'CHARGING_COMPLETED':
      return (
        <div className={classes.imageBox}>
          <EvStationIcon
            style={{fill: '#30AD51', fontSize: fontSize}}
          />
        </div>
      )
    case 'CHARGING':
    case 'CHARGING_STARTED_EV':
      return (
        <div className={classes.imageBox}>
          <Poller action={() => setChargingIcon()} interval={1000}/>
          {icon}
        </div>
      )
    case 'AVAILABLE':
      return (
        <div className={classes.imageBox}>
          <EvStationIcon
            style={{fill: '#B2DF8A', fontSize: fontSize}}
          />
        </div>
      )
    case 'CHARGING_ALLOWED':
      return (
        <div className={classes.imageBox}>
          <EvStationIcon
            style={{color: '#F9C510', fontSize: fontSize}}
          />
        </div>
      )
    case 'CONNECTED':
    case 'CHARGING_SUSPENDED_EV':
      return (
        <div className={classes.imageBox}>
          <EvStationIcon
            style={{color: '#F9C510', fontSize: fontSize}}
          />
        </div>
      )
    case 'UNAUTHORIZED':
      return (
        <div className={classes.imageBox}>
          <div className={classes.lockBox}>
            <EvStationIcon
              style={{
                color: '#F9C510',
                fontSize: fontSize,
                marginBottom: lockMarginBottom
              }}
            />
            <LockIcon style={{marginLeft: lockMarginLeft}}/>
          </div>
        </div>
      )
    case 'FAULTY_LOCKED':
      return (
        <div className={classes.imageBox}>
          <EvStationIcon
            style={{fill: '#C00000', fontSize: fontSize}}
          />
        </div>
      )
    case 'UNDEFINED':
    default:
      return (
        <div className={classes.imageBox}>
          <EvStationIcon
            style={{fill: '#B9B9B9', fontSize: fontSize}}
          />
        </div>
      )
  }
}

ChargingPointSvg.propTypes = {
  chargingStatus: PropTypes.string
}

export default ChargingPointSvg
