import React from "react";
import {makeStyles} from "@mui/styles";
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow, Typography
} from "@mui/material";
import {DateTime} from "luxon";
import 'typeface-noto-sans'

const useStyles = makeStyles( (theme) => ({
  box: {
    margin: '16px',
    borderRadius: '8px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  tableRoot: {
    marginTop: theme.spacing(2)
  },
  leftCell: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontFamily: 'Noto Sans',
  },
  middleCell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontFamily: 'Noto Sans',
  },
  rightCell: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontFamily: 'Noto Sans',
  },
  buttonBox: {
    fontSize: '12px',
    backgroundColor: theme.palette.divider
  }
}))

const ChargingTimeCard = ({ chargingSessions, fetchMoreData, last }) => {
  const classes = useStyles()

  return (
    <Card className={classes.box} spacing={2} elevation={2}>
      {chargingSessions.length > 0 ?
        <Table size={"small"} className={classes.tableRoot}>
          <TableBody>
            {chargingSessions.map((session) => (
              <TableRow key={session.startDateTime}>
                <TableCell className={classes.leftCell}>
                  {DateTime.fromISO(session.startDateTime).toFormat('dd.MM.yy')}
                </TableCell>
                <TableCell className={classes.middleCell} align={"center"}>
                  {DateTime.fromISO(session.startDateTime).toFormat('HH:mm') + ' - ' + DateTime.fromISO(session.endDateTime).toFormat('HH:mm')}
                </TableCell>
                <TableCell className={classes.middleCell} align={"center"}>
                  {session.duration}
                </TableCell>
                <TableCell className={classes.rightCell} align={"right"}>
                  {(Math.round((session.quantity.value / 1000) * 100) / 100).toFixed(2).toString().replace('.', ',') + ' kWh'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        :
        <Typography variant={"body1"} align={"center"} color={"secondary"} paddingBottom={1} paddingTop={1}>
          Keine Ladevorgänge gefunden
        </Typography>
      }
      {
        last === false &&
        <Button className={classes.buttonBox} color={"secondary"} onClick={() => fetchMoreData()}>
          MEHR
        </Button>
      }
    </Card>
  )
}

export default ChargingTimeCard
