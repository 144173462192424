import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from "./theme";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {CssBaseline} from "@mui/material";
import { ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import MyCssBaseline from "./theme/MyCssBaseline";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import './index.css'

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline/>
          <MyCssBaseline />
          <App/>
        </StyledEngineProvider>
      </ThemeProvider>
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    registration.unregister().then(() => {
      console.log('reload')
      window.location.reload()
    })
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
