import React from 'react'
import { useNavigate } from 'react-router'
import {makeStyles} from "@mui/styles";
import {Button, Typography} from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
    paddingTop: theme.spacing(8)
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentBody: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  title: {
    color: theme.palette.white,
  },
  titleBox: {
    marginTop: theme.spacing(8)
  }
}))

const ResetPasswordCompleted = () => {

  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <div className={classes.root}>
          <div className={classes.contentBody}>
            <div className={classes.form}>
              <div className={classes.titleBox}>
                <Typography
                  className={classes.title}
                  variant="h4">
                  GESCHAFFT!
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  className={classes.title}
                >
                  Ihr Passwort wurde geändert. Sie können sich jetzt mit ihrem neuen Passwort anmelden.
                </Typography>
              </div>
              <Button
                variant={"contained"}
                color={"primary"}
                fullWidth
                onClick={() => {
                  navigate('/')
                }}
              >
                Zurück zur Anmeldung
              </Button>
            </div>
          </div>
    </div>
  )
}

export default ResetPasswordCompleted
